.lightShadow {
  -webkit-box-shadow: 0px 0px 2px 1px rgba(179, 179, 179, 0.6);
  -moz-box-shadow: 0px 0px 2px 1px rgba(179, 179, 179, 0.6);
  box-shadow: 0px 0px 2px 1px rgba(179, 179, 179, 0.6);
}

.backLightShadow {
  -webkit-box-shadow: 0px 0px 50px 17px rgba(179, 179, 179, 0.6);
  -moz-box-shadow: 0px 0px 50px 17px rgba(179, 179, 179, 0.6);
  box-shadow: 0px 0px 50px 17px rgba(179, 179, 179, 0.6);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mainContainer {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100svh;
  background-color: rgb(72, 72, 72);
}

.todoFormContainer {
  @extend .backLightShadow;
  margin-inline: auto;
  padding: 0.75em;
  width: clamp(250px, 100%, 500px);
  background-color: rgb(51, 51, 51);
  animation: fadeIn 0.6s linear forwards;
}

.todoForm {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.formSection {
  @extend .lightShadow;
  width: 100%;
  padding: 0.2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  border: 1px solid #2a2a2a;
  border-radius: 4px;

  background: linear-gradient(
    to bottom,
    rgba(235, 235, 235, 1) 0%,
    rgb(206, 206, 206) 100%
  );
}

.formSection input:not([type="checkbox"]),
textarea {
  flex-grow: 1;
  font-size: 1.1em;
  line-height: 1.8em;
  padding: 0.2em;
  border-width: 0;
  background-color: transparent;
}

.formSection input[type="checkbox"] {
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  outline: none;
}

.formBtn {
  @extend .lightShadow;
  color: #181818;
  border: 1px solid #2a2a2a;
  border-radius: 3px;
  font-size: 1.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.formSection .addBtn {
  @extend .formBtn;
  background: linear-gradient(
    to bottom,
    rgba(183, 235, 207, 1) 0%,
    rgba(113, 237, 138, 1) 50%,
    rgba(40, 224, 33, 1) 51%,
    rgba(81, 235, 70, 1) 100%
  );
}

.formSection .removeBtn {
  @extend .formBtn;
  background: linear-gradient(
    to bottom,
    rgba(248, 80, 50, 1) 0%,
    rgba(241, 111, 92, 1) 50%,
    rgba(246, 41, 12, 1) 51%,
    rgba(240, 47, 23, 1) 71%,
    rgba(231, 56, 39, 1) 100%
  );
}

.todoItems {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.2em;
  opacity: 0;
  animation: fadeIn 0.6s linear 0.1s forwards;
}

.todoItem {
  padding-inline: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  background-color: rgb(255, 255, 255);
  border-top: 1px solid rgb(146, 146, 146);
  border-bottom: 1px solid rgb(52, 52, 52);
}

.todoItemComplete {
  @extend .todoItem;
  background: linear-gradient(
    10deg,
    rgba(179, 220, 237, 1) 0%,
    rgba(41, 184, 229, 1) 10%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}

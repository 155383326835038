.snakeGrid {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

.snakeGrid > * {
  min-height: 6vw;
  min-width: 2vw;
  border-color: black;
  border-width: 1px;
  border-style: solid;
}

$mycolor: rgb(0, 33, 249);

.container {
  padding: 2em;
}

.headerz {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgb(234, 234, 234);
}

.backGround {
  background: linear-gradient(#305589, #a5d4ff);
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  background-size: 200% 200%;
  -webkit-animation: titleBG 10s ease infinite;
  -moz-animation: titleBG 10s ease infinite;
  animation: titleBG 10s ease infinite;
}

.title {
  margin-top: 20vh;
  text-align: center;
  font-size: 10vw;
  color: rgb(255, 222, 172);
  text-shadow: rgb(32, 32, 32) 4px 0 10px;
  text-decoration: underline dotted 5px;
}

.title > button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: auto;
  outline: inherit;
  text-decoration: inherit;
  text-shadow: inherit;
}

.funForm {
  margin-top: 30px;
  display: flex;
  gap: 5px;
  justify-content: center;
}

.funForm input,
.funForm button {
  padding: 10px;
  border-radius: 5px;
  border-width: 1px;
  size: 5vw;
}

@keyframes titleBG {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@-webkit-keyframes titleBG {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@-moz-keyframes titleBG {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}

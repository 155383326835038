$squareSize: 100px;

.hwSquare {
  width: $squareSize;
  height: $squareSize;
  line-height: $squareSize;
  text-align: center;
  background-color: orange;
  margin: 5px;
}

.jokeBox {
  padding: 10px;
  margin: 10px;
  width: 300px;
  box-shadow: 7px 7px 5px rgb(74, 74, 74);
  border-radius: 8px;
  border {
    color: rgb(0, 0, 0);
    size: 1px;
  }
  background-color: rgb(133, 162, 202);
  text-align: center;
}

.exerciseContainer {
  margin-bottom: 20px;
}

.excerciseHeader {
  color: rgb(46, 46, 46);
  font-size: small;
  padding: 5px;
  margin-bottom: 20px;
  width: 500px;
  box-shadow: 0px 2px 3px rgb(163, 163, 163);
  border-radius: 3px;
  border {
    color: rgb(0, 0, 0);
    size: 1px;
  }
  background-image: linear-gradient(rgb(238, 238, 238), rgb(213, 213, 213));
}

.greetForm {
  & div {
    margin: 10px;

    & input {
      margin-left: 5px;
    }
  }
}

// button {
//   margin: 4px;
// }

@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";

/* GLOBAL STYLES */

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

// input {
//   line-height: 1em;
// }

// body {
//   font: 14px "Century Gothic", Futura, sans-serif;
// }

// ol,
// ul {
//   padding-left: 30px;
// }

.boardRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.boardRow:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 12vw;
  font-weight: bold;
  line-height: 1vw;
  height: 13vw;
  margin-right: -1px;
  margin-top: -1px;
  padding-top: 0px;
  text-align: center;
  width: 13vw;
}

.square:focus {
  outline: none;
}

// .kbd-navigation .square:focus {
//   background: #ddd;
// }

.game {
  display: flex;
  flex-direction: row;
  //min-width: 600px;
}

.gameInfo {
  margin-left: 20px;
}
